import React from "react";
import Category from "./Category";
import { RaceCompetitor } from "@/types/LeaderboardTypes";

interface BoatProps {
  data: RaceCompetitor[] | undefined;
  name: string;
  distance: number;
}

const Boat: React.FC<BoatProps> = ({ data, name, distance }) => {
  const filterByCategory = (ageBracket: string, gender: string) => {
    return (
      data?.filter(
        (competitor) =>
          competitor.ageBracket === ageBracket && competitor.gender === gender
      ) || []
    );
  };

  const u19Female = filterByCategory("U19", "Female");
  const openFemale = filterByCategory("Open", "Female");
  const u19Male = filterByCategory("U19", "Male");
  const openMale = filterByCategory("Open", "Male");

  return (
    <div className="boat">
      <div className="boat-header">
        <h1>{name}</h1>
      </div>

      <div className="boat-container">
        <Category header="U19 Female" data={u19Female} />

        <Category header="Open Female" data={openFemale} />

        <Category header="U19 Male" data={u19Male} />

        <Category header="Open Male" data={openMale} />
      </div>
    </div>
  );
};

export default Boat;
