import React from "react";

interface TimingProps {
  name: string;
  time: number | string;
  position: number;
}

const Timing: React.FC<TimingProps> = ({ name, time, position }) => {
  var badgeStyle = {};
  if (position === 1) {
    badgeStyle = {
      background:
        "linear-gradient(149deg, rgba(136, 106, 0, 1) 0%, rgba(252, 204, 62, 1) 65%, rgba(241, 223, 165, 1) 100%)",
      color: "white",
    };
  } else if (position === 2) {
    badgeStyle = {
      background: "linear-gradient(149deg, #808080 0%, #C0C0C0 100%)",
      color: "white",
    };
  } else if (position === 3) {
    badgeStyle = {
      background: "linear-gradient(149deg, #8B4513 0% , #DAA520 100%)",
      color: "white",
    };
  } else {
    badgeStyle = {
      backgroundColor: "white",
      color: "var(--spaceGrey)",
    };
  }
  return (
    <div className="timing" style={{ ...badgeStyle }}>
      <h3 className="rank">{position}</h3>
      <div className="name">{name.trim()}</div>
      <div className="time">{time}s</div>
    </div>
  );
};

export default Timing;
