import React, { useState, useEffect } from "react";
import { initializeApp, getApps, getApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import ResultsBoat from "./Boat";
import { RaceCompetitor } from "../types/LeaderboardTypes";
import StatsBox from "./StatsBox";

import logo from "../assets/rowcave_logo_black.svg";
import TeamNL from "../assets/teamnl.svg";
import moveLab from "../assets/logo-movelab-colour.svg";
import rp3logo from "../assets/rp3-logo-grey.svg";
import NKIR from "../assets/NKIR.png";
import fulhamPier from "../assets/logos/logo-fulham_pier_working.svg";
import theBoatRace from "../assets/logos/logo-the-boat-race.svg";

const firebaseConfig = {
  apiKey: "AIzaSyCdUJ1nMEBLxn1xJ5u6hsJv0dMK4sk2Nms",
  authDomain: "rowcave-leaderboard.firebaseapp.com",
  databaseURL:
    "https://rowcave-leaderboard-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "rowcave-leaderboard",
  storageBucket: "rowcave-leaderboard.appspot.com",
  messagingSenderId: "332419237985",
  appId: "1:332419237985:web:a51ed155520450b7308220",
};

// Proper Firebase initialization
const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
const database = getDatabase(app);

const Leaderboard: React.FC = () => {
  const [data, setData] = useState<RaceCompetitor[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    try {
      const url = new URL(window.location.href);
      const pathname = url.pathname.substring(1); // Remove leading slash
      const dbRefPath = pathname || "testLeaderboard"; // Provide a default path if empty

      const workoutsRef = ref(database, dbRefPath);

      setLoading(true);
      const unsubscribe = onValue(
        workoutsRef,
        (snapshot) => {
          const newData: RaceCompetitor[] = [];
          snapshot.forEach((childSnapshot) => {
            const value = childSnapshot.val() as RaceCompetitor;
            newData.push(value);
          });
          setData(newData);
          setLoading(false);
        },
        (error) => {
          console.error("Database error:", error);
          setError(error.message);
          setLoading(false);
        }
      );

      return () => unsubscribe();
    } catch (error) {
      console.error("Error setting up database listener:", error);
      setError(error instanceof Error ? error.message : "Unknown error");
      setLoading(false);
    }
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const filterDataByBoatSize = (boatSize: number): RaceCompetitor[] => {
    return data.filter((competitor) => competitor.boatSize === boatSize);
  };

  return (
    <>
      <div className="overall">
        <div className="content">
          <div className="results">
            <ResultsBoat
              name="Single"
              distance={250}
              data={filterDataByBoatSize(1)}
            />
            <ResultsBoat
              name="Double"
              distance={250}
              data={filterDataByBoatSize(2)}
            />
            <ResultsBoat
              name="Quad"
              distance={250}
              data={filterDataByBoatSize(4)}
            />
            <StatsBox data={data} />
          </div>
        </div>
        <div className="headerBox">
          <div className="sponsors">
            <img
              className="image"
              alt=""
              style={{ height: "3.8vw" }}
              src={logo}
            />
            <img
              className="image"
              alt=""
              style={{ height: "5vw" }}
              src={fulhamPier}
            />
            <img
              className="image"
              alt=""
              style={{ height: "5vw" }}
              src={theBoatRace}
            />
            <img
              className="image"
              alt=""
              style={{ height: "5vw" }}
              src={fulhamPier}
            />
            <img
              className="image"
              alt=""
              style={{ height: "3.8vw" }}
              src={logo}
            />
          </div>
        </div>
      </div>
      <div className="background" />
    </>
  );
};

export default Leaderboard;
